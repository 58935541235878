import { defineStore } from 'pinia'
import type { BookingLimitsInterface, PromoCodeInterface } from '@playa/types'

const useBookingWidgetStore = defineStore('bookingWidgetStore', () => {
  const showBookingModal = ref(null) as Ref<string | null>
  const bookingExcludeResorts = ref('')

  const resortPromoCodes = ref([]) as Ref<Array<{
    resort_id: number
    type: string
    value: string
    currency: string
    type_reservhotel: string
    value_reservhotel: string
  }>>

  const bookingDateLimits = ref({
    min: null,
    max: null,
  }) as BookingLimitsInterface

  const promoCodes = ref({
    promo: null,
    coupon: null,
    rate: null,
    group: null,
    configcode: 'PlayaWebsite',
    themecode: 'PlayaWebsite',
    currency: 'USD',
  }) as PromoCodeInterface

  function shouldShowBookingModal() { return showBookingModal.value !== null }

  function toggleBookingModal(id: string) {
    if (showBookingModal.value !== id)
      showBookingModal.value = id

    else
      showBookingModal.value = null
  }
  function setBookingDateLimits(limits: BookingLimitsInterface) {
    bookingDateLimits.value = limits
  }

  function setBookingExcludeResorts(value: string) {
    bookingExcludeResorts.value = value
  }
  function setPromoCodes(codes: PromoCodeInterface) {
    promoCodes.value = codes
  }

  return {
    showBookingModal,
    shouldShowBookingModal,
    toggleBookingModal,
    bookingDateLimits,
    setBookingDateLimits,
    bookingExcludeResorts,
    setBookingExcludeResorts,
    promoCodes,
    setPromoCodes,
    resortPromoCodes,
  }
})

export { useBookingWidgetStore }
